import React from 'react'
import img1 from '../../../images/minik1.jpg'
import img2 from '../../../images/minik2.jpg'
import styled from 'styled-components'
import SmallTitle from '../../atoms/SmallTitle/SmallTitle'
import SectionTitle from '../../atoms/SectionTitle/SectionTitle'
import Content from '../../atoms/Content/Content'
import ScrollWrapper from '../../atoms/ScrollWrapper/ScrollWrapper'

const Minik = () => {
    return (
        <ScrollWrapper id="minikoparka">
            <Wrapper className="container" >
                <Imgs>
                    <img className="img1" src={img1} alt="uslugi minikoparką gaz system radom" />
                    <img className="img2" src={img2} alt="zdjęcie uslugi minikoparką gaz system " />
                </Imgs>
                <Text>

                    <SmallTitle text="Nasza oferta" />
                    <SectionTitle text="USŁUGI MINIKOPARKĄ" />
                    <Content>
                        <ul>
                            <li>fundamenty budynków i ogrodzeń</li>
                            <li>wykopy pod instalacje: wodne, elektryczne, kanalizacyjne, światłowodowe</li>
                            <li>oczka wodne</li>
                            <li>izolacje fundamentów budynku</li>
                            <li>prace gdzie wymagane są małe gabaryty i zwrotność, gdzie szerokość koparki to zaledwie 100 cm</li>
                            <li>max głębokość kopania 2.20 m</li>
                        </ul>
                    </Content>

                </Text>
            </Wrapper>
        </ScrollWrapper>
    )
}

const Wrapper = styled.div`
    
    margin-top: 50px;
    /* margin-bottom: 50px; */
    display: flex;
    align-items: center;
    flex-direction: column;

    @media (min-width: 768px) {
        flex-direction: row;
    }

    @media (min-width: 992px) {
        margin-top: 150px;
        margin-bottom: 150px;
    }

    @media (min-width: 768px) and (max-width: 1230px) {
        max-width: calc(100% - 30px);
    }


    > div {
        width: 100%;
        @media (min-width: 768px) {
            width: 50%;
        }
    }

`
const Imgs = styled.div`

    .img1 {
        width: 75%;
        height: auto;
    }

    .img2 {
        width: 240px;
        height: 240px;
        object-fit: cover;
        display: block;
        margin-right: 0;
        margin-left: auto;
        margin-top: -120px;
    }

    @media (max-width: 767.9px) {
        order: 1;
        .img1 {display: none}
        .img2 {width: 100%; height: 60vw; max-height: 400px; margin-top: 10px;}
    }

`

const Text = styled.div`

    padding: 30px;

    @media (min-width: 768px) {
        padding: 30px 30px 30px 50px;
    }

`

export default Minik;