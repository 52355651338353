const router = [

    {
        path: "/",
        name: "Strona Główna"

    },
    {
        path: "#onas",
        name:"O nas"
    },
    {
        path: "",
        name: "Oferta",
        links: [
            {
                path: "#zbiorniki",
                name: "Zbiorniki gazowe"
            
            },
            {
                path: "#minikoparka",
                name:"Usługi minikoparką"
            },
          
            {
                path: "#przylacza",
                name: "Przyłącza i sieci"
            
            },
            {
                path: "#przeciski",
                name: "Przeciski podjezdniowe"
            
            },
            {
                path: "#instalacje",
                name: "Wewnętrzne instalacje sanitarne"
            
            },
            {
                path: "#projekt",
                name: "Projektowanie, wykonawstwo"
            
            },
        ],
    },
    {
        path: "#kontakt",
        name:"Kontakt"
    }

];

export default router;
