import React  from 'react';
import { Link } from '@reach/router'
import styled from 'styled-components'
import { useGlobalContext } from '../../../context/GlobalContext';


const NavItem = ({name, links}) => {
    
    const {closeNav} = useGlobalContext();

    return (
        <Li>
            <span>{name}<span className="">:</span></span>
            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M128 192l128 128 128-128z"></path></svg>
            <ul className="sub-menu">
                {links.map(link=><li><Link to={link.path} onClick={closeNav} >{link.name}</Link></li>)}
            </ul>
        </Li>
    )
}

const Li = styled.li`

    margin: 5px 0;
    text-transform: uppercase;
    cursor: pointer;

    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: 768px) {
        margin: 0 7px;
        position: relative;
        height: 65px;
        display: flex;
        align-items: center;
        flex-direction: row;

        &:hover {
            ul {
                display: flex;
            }
        }
    }

    @media (min-width: 992px) {
        margin: 0 13px;
    }

    svg {
        display: none; 
        @media (min-width: 768px) {
            display: inline-block;
        }
    }

    > span > span {
        @media (min-width: 768px) {
            display: none;
        }
    }

    a {
        text-decoration: none;
        color: var(--black);
        
        @media (min-width: 1024px) {
            text-transform: uppercase;
        }
    }

    

    .sub-menu {
        li {
            @media (max-width: 767.9px) {
                font-size: .85em;
            }
        }
        margin-bottom: 20px;
        @media (min-width: 768px) {
            margin-bottom: 0px;
            position: absolute;
            left: -20px;
            padding: 30px 20px 20px;
            bottom: -360px;
            background-color: white;
            width: 200px;
            height: auto;
            flex-direction: column;
            box-shadow: 0 17px 15px 5px rgba(0,0,0,.15);
            align-items: flex-start;
            display: none;

            li {
                margin-bottom: 20px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

`

export default NavItem;