import React from 'react'
import styled from 'styled-components'


const ScrollWrapper = ({id,children}) => {
    return (
        <Wrapper id={id}>{children}</Wrapper>
    )
}

const Wrapper = styled.div`

    margin-top: -50px;
    padding-top: 50px;

    @media (min-width: 768px) {
        margin-top: -70px;
        padding-top: 70px;
    }
`

export default ScrollWrapper