import React, {useState, useEffect} from 'react';
import styled from "styled-components";
import Logo from "../../atoms/Logo/Logo";
import Nav from "../../molecules/Nav/Nav";
import { FiMenu } from 'react-icons/fi'
import { useGlobalContext } from '../../../context/GlobalContext';

const Menu = () => {

    const { openNav } = useGlobalContext();
    const [top, setTop] = useState(true)

    useEffect(() => {
        window.onscroll = function() {scrollFunction()};

        function scrollFunction() {
        if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
            setTop(false)
        } else {
            setTop(true)
        }
        }
    }, [])

    return (
        <StyledNav>
            <div className="container">
                <Logo top={top} />
                <FiMenu onClick={openNav} />
                <Nav top={top} />
            </div>
        </StyledNav>
    )
}

const StyledNav = styled.nav`

    width: 100%;
    box-shadow: 0 0 15px 5px rgba(0,0,0,.15);

    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 150;
    background-color: var(--white);

    .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px;

        >svg {
            width: 26px;
            height: auto;
            cursor: pointer;
            @media (min-width: 768px) {
                display: none;
            }
        }
    }

`

export default Menu;