import React from 'react'
import styled from 'styled-components'

const SmallTitle = ({text}) => <H2>{text}</H2>

const H2 = styled.h2`

    letter-spacing: 0.5em;
    font-size: .9em;
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 0;

`

export default SmallTitle