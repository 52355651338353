import styled from 'styled-components'
import React from 'react'

const SectionTitle = ({text}) => <H3>{text}</H3>

const H3 = styled.h3`

    color: var(--primary);
    font-size: 1.8em;
    font-weight: 600;
    margin-bottom: 20px;
    margin-top: 0;
    text-transform: uppercase;

`

export default SectionTitle;