import { createGlobalStyle } from 'styled-components';
 
const GlobalStyle = createGlobalStyle`
    :root {
        --primary: #FF4100;
        --white: #ffffff;
        --black: #000000;
        --gray: #F8F8F8;
    }
    * {
        box-sizing: border-box;
        padding: 0;
        margin: 0;
    }
    html {
        scroll-behavior: smooth;
    }
    body {
        margin: 0;
        padding: 0;
        font-family: Montserrat, Sans-Serif;
        font-size: 14px;
        background-color: var(--gray);

        @media (min-width: 768px) {
            font-size: 16px;
        }

        /* @media (min-width: 992px) {
            font-size: 18px;
        } */
    }
    .container {
        max-width: 1200px;
        margin-left: auto;
        margin-right: auto;
        width: 100%;

        
    }
    img {
        max-width: 100%;
    }
    a {
        text-decoration: none;
    }
`;
 
export default GlobalStyle;