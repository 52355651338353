
import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components'

const Button = ({path, text}) => {
    
    return (<StyledButton to={path}>{text}</StyledButton>)
}

const StyledButton = styled(Link)`

    background-color: var(--primary);
    border: 2px solid var(--primary);
    color: var(--white);
    padding: 10px 20px;
    display: inline-block;
    text-transform: uppercase;
    letter-spacing: .2em;
    font-size: .75em;
    font-weight: 600;

    transition: all .3s;

    &:hover {
        background-color: transparent;
        color: var(--primary);
    };


`

export default Button;