import React from 'react'
import styled from 'styled-components'
import Content from '../../atoms/Content/Content'
import ScrollWrapper from '../../atoms/ScrollWrapper/ScrollWrapper'
import SectionTitle from '../../atoms/SectionTitle/SectionTitle'
import SmallTitle from '../../atoms/SmallTitle/SmallTitle'

const Section = ({img, smallTitle, title, leftImg = false, children, id = title}) => (
    <ScrollWrapper id={id}>
        <Wrapper leftImg={leftImg} >
            <Text className="container" leftImg={leftImg}>
                <div >
                    <SmallTitle text={smallTitle} />
                    <SectionTitle text={title} />
                    <Content>{children}</Content>
                </div>
            </Text>
            <Img leftImg={leftImg} className="imgs"><img src={img} alt={title + ' gaz system radom'} /> </Img>
        </Wrapper>
    </ScrollWrapper>

)



const Wrapper = styled.div`

    background-color: var(--white);
    position: relative;
    
    @media (min-width: 768px) {
        display: flex;
        align-items: stretch;
        min-height: 500px;
    }

    @media (min-width: 1800px) {
        min-height: 700px;
    }

`
const Text = styled.div`

    padding: 50px 30px;

    display: flex;
    align-items: center;

    > div {
        @media (min-width: 768px) {
            width: 50%;
            padding-right: ${({leftImg})=>leftImg ? 'unset' : '50px'};
            padding-left: ${({leftImg})=>leftImg ? '50px' : 'unset'};
            margin-left: ${({leftImg})=>leftImg ? '50%' : 'unset'}
        }
    }

`
const Img = styled.div`

    @media (max-width: 767.9px){
        order: 1;
        height: 60vw; 
        max-height: 400px;
    }

    width: 100%;
    height: inherit;


    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 50% 20%;
    }

    @media (min-width: 768px) {
        position: absolute;
        width: 50vw;
        height: 100%;
        top: 0;
        left: ${({leftImg})=>leftImg ? 0 : 'unset'};
        right: ${({leftImg})=>leftImg ? 'unset' : 0};
    }

`


export default Section;