import React, { useState, useContext } from "react";

const GlobalContext = React.createContext({});

export const useGlobalContext = () => useContext(GlobalContext);

const GlobalProvider = ({ children }) => {

    const [isNavOpen, setIsNavOpen] = useState(false)

    const openNav = () => setIsNavOpen(true)
    const closeNav = () => setIsNavOpen(false)

    const value = {
        isNavOpen,
        openNav,
        closeNav
	};


    return (
		<GlobalContext.Provider value={value}>
			{children}
		</GlobalContext.Provider>
	);


}

export default GlobalProvider
