import React from 'react';
import styled from "styled-components";
import hero from '../../../images/hero.jpg'
import { FaMapMarkerAlt } from 'react-icons/fa'
import { AiTwotonePhone } from 'react-icons/ai'
import Button from '../../atoms/Button/Button';


const Hero = () => {
    return (
        <>
            <Header />
            <ScrollWrapper id="onas">
            <Info className="container" >
                <div className="col col--left">
                    <InfoItem>
                        <FaMapMarkerAlt />
                        <p>ul. Krasickiego 48, <br/>Skaryszew</p>
                    </InfoItem>
                    <InfoItem>
                        <AiTwotonePhone />
                        <div>
                        <a href="tel: +48 511 010 725">(+48) 511 010 725</a>  <br/>
                        <a href="tel: +48 531 840 280">(+48) 531 840 280</a>
                        </div>
                    
                    </InfoItem>
                </div>
                <div className="col col--right">
    
                    <p>Nasza firma świadczy usługi w obrębie <span>Radomia, Warszawy i okolic</span>. Najważniejsze jest dla nas zadowolenie klienta, a profesjonalne podejście pozwala nam czerpać satysfakcję z wykonanych prac.</p>
                    <Button path="#kontakt" text="Skontaktuj się z nami" />
                
                </div>
              
            </Info>
            </ScrollWrapper>
        </>
    )
}
const ScrollWrapper = styled.div`

    margin-top: -110px;
    padding-top: 110px;

    @media (min-width: 768px) {
        margin-top: -175px;
        padding-top: 175px;
        margin-bottom: 100px;
    }

`

const Header = styled.header`

    background-image: url('${hero}');
    background-size: cover;
    background-position: 70% 0%;
    background-size: 180vw;

    height: 55vh;

    position: relative;


    @media (min-width: 570px){
        height: 62vw;
        background-position: 50% 55%;

    }

    @media (min-width: 992px){
        height: 85vh;
        min-height: 500px;
        background-size: 120vw;
        background-position: 50% 30%;
    }

`

const Info = styled.div`
 
    background-color: var(--white);
    padding: 15px 15px 20px;
    text-align: center;
    margin-top: -30px;
    position: relative;
    box-shadow: 0 0 10px 5px rgba(0,0,0,.1);

    @media (max-width: 1230px) {
        max-width: calc(100% - 30px);
    }

    @media (min-width: 768px) {
        display: flex;
        align-items: stretch;
        padding: 30px;

        .col--left {
            width: 250px;
            border-right: 1px solid var(--primary);

            @media (min-width: 992px){
                width: 300px;
            }
        }
        .col--right {
            width: calc(100% - 250px);
            text-align: left;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 5px 0 5px 30px;

            @media (min-width: 992px){
                width: calc(100% - 300px);
                padding: 15px 0 15px 30px;
            }
            
            @media (min-width: 1200px) {
                padding: 15px 0 15px 50px;
            }

            span {
                color: var(--primary);
            }

            a {
                max-width: 280px;
                text-align: center;
                
            }
        
        }
    }

    @media (min-width: 992px) {
        margin-top: -100px;
    }

    .col--right {
        a {
            margin-top: 20px;

        }
    }

`

const InfoItem = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;
    margin: 10px 0 30px;

    @media (min-width: 768px) {
        justify-content: flex-start;
        
    }

    @media (max-width: 767.9px) {
        a,p {
            font-size: 1.15em;
        }
    }

    a, p {
        color: var(--black);
        /* font-size: 1.2em; */
        margin: 0;
    }

    /* a {
        font-size: 1.25em;
    } */

    svg {
        fill: var(--primary);
        font-size: 1.5em;
        margin-right: 10px;
    }

`



export default Hero;