import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import logo from '../../../images/logo.png'

const Logo = ({top}) => <H1 top={top}><Link to="/"><img src={logo} alt="system-gaz"/></Link></H1>

const H1 = styled.h1`

    margin: 0;

    img {
        width: ${({top})=>top ? '92px' : '80px'};
        height: auto;
        transition: all .3s;

        @media (min-width: 992px) {
            width: ${({top})=>top ? '132px' : '90px'};
        }
    }

`

export default Logo;