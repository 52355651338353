import React  from 'react';
import { Link } from '@reach/router'
import styled from 'styled-components'
import { useGlobalContext } from '../../../context/GlobalContext';


const NavItem = ({name, path}) => {
    
    const {closeNav} = useGlobalContext();

    return (
        <Li><Link to={path} onClick={closeNav} >{name}</Link></Li>
    )
}

const Li = styled.li`

    margin: 5px 0;

    @media (min-width: 768px) {
        margin: 0 7px;
    }

    @media (min-width: 992px) {
        margin: 0 13px;
    }

    a {
        text-decoration: none;
        color: var(--black);
        
        @media (min-width: 1024px) {
            text-transform: uppercase;
        }
    }

`

export default NavItem;