import React from 'react';
import styled from "styled-components";
import router from "../../../assets/router";
import { useGlobalContext } from '../../../context/GlobalContext';
import NavItem from "../../atoms/NavItem/NavItem";
import NavItems from "../../atoms/NavItems/NavItems";
import { GrClose } from 'react-icons/gr'


const Nav = () => {
    const { isNavOpen, closeNav } = useGlobalContext(); 
    return (
        <NavWrapper open={isNavOpen}>
            <ul >
                <GrClose onClick={closeNav}/>
                {router.map((route,i)=> route.path ? <NavItem path={route.path} name={route.name} key={i} /> : <NavItems name={route.name} links={route.links} />)}
            </ul>
        </NavWrapper>
    )
}

const NavWrapper = styled.aside`

    background-color: var(--white);
    
    @media (max-width: 767.9px) {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        transform: ${({open})=>open ? 'translateX(0vw)' : 'translateX(100vw)'};
        transition: all .3s;
        z-index: 100;
    }


   
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        width: 100%;
        height: 100%;
        
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        position: relative;

        @media (min-width: 768px) {
            flex-direction: row;
        }

        li {
            @media (max-width: 767.9px) {
                font-size: 1.15em;
                line-height: 2.5em;
            }

            a {
                transition: all .3s;
                &:hover {
                color: var(--primary);
                }
            }
        }

        >svg {
            position: absolute ;
            top: 25px;
            right: 15px;
            width: 24px;
            height: auto;
            @media (min-width: 768px) {display: none}
        }
    }

`

export default Nav;