import React from 'react'
import styled from 'styled-components';
import ScrollWrapper from '../../atoms/ScrollWrapper/ScrollWrapper';

const Footer = () => {
    return (
        <ScrollWrapper id="kontakt">
        <FooterWrapper >
            <div className="container">
                <Question>
                    <h3>Potrzebujesz wyceny, a może informacji dotyczącej oferty?</h3>
                    <p>Projektowanie i budowanie sieci:  <a href="tel:+48 730 008 207">(+48) 730 008 207</a> </p>
                    <p>Projektowanie i budowa przyłączy:  <a href="tel:+48 531 840 280">(+48) 531 840 280</a></p>
                    <p>Projektowanie i budowa wewnętrznych instalacji:  <a href="tel:+48 798 424 610">(+48) 798 424 610</a></p>
                    <p>Napisz do nas: <a href="mailto:biuro@system-gaz.pl">biuro@system-gaz.pl</a></p>
                </Question>
                <Hours>
                    <H3>Godziny otwarcia</H3>
                    <p>
                        Poniedziałek - Piątek <br/>
                        08:00 - 16:00 <br/><br/>
                        
                        Sobota<br/>
                        10:00 - 14:00<br/>
                    </p>
                </Hours>
                <Address>
                    <H3>Adres</H3>
                    <p>
                        ul. Krasickiego 48,<br/>
                        26-640 Skaryszew
                    </p>

                </Address>
            </div>
           <Copy> <span>&copy; 2021 created by <a href="https://anielakm.pl">Aniela Katana-Matłok</a> for System Gaz.</span><span> All Rights Reserved.</span></Copy>
        </FooterWrapper>
        </ScrollWrapper>
    )
}

const FooterWrapper = styled.footer`

    
    text-align: center;

    @media (min-width: 768px) {
        text-align: left;
        

    }

    .container {
        display: flex;
        flex-direction: column;
        padding: 50px 15px 0;
        @media (min-width: 768px) {
            flex-direction: row;
            padding: 80px 15px 30px;
        }
    }

`

const Question = styled.div`

    flex: 2;

    @media (min-width: 768px) {
        padding-right: 30px;
    }

    h3 {
        font-size: 1.2em;
        line-height: 1.6em;
        margin-bottom: 25px;
        max-width: 300px;
        @media (max-width: 767.9px) {
            margin-left: auto;
            margin-right:auto;
        }
    }

    a {
        color: var(--primary);
        font-size: 1.2em;
        line-height: 1.5em;
        font-weight: 600;
        display: inline-block;

       
    }

    p {
        font-size: .8em;

        @media (max-width: 767.9px) {
            font-size: 1em;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        
    }

`

const H3 = styled.h3`

    font-size: 1em;
    line-height: 1.5em;
    margin-bottom: 20px;
    font-weight: 600;


`

const Hours = styled.div`
    flex: 1;
    @media (max-width: 767.9px) {
        margin: 40px 0;
    }
`
const Address = styled.div`
    flex: 1;
`

const Copy = styled.div`
    margin-top: 50px;
    font-size: .75em;
    text-align: center;
    padding: 20px 0;
    background-color: var(--white);

    @media (max-width: 767.9px) {
        display: flex;
        flex-direction: column;
    }

    a {
        color: var(--primary);
        opacity: .8;
    }
`

export default Footer;