import * as React from "react"
import styled from "styled-components"
import GlobalStyle from "../assets/GlobalStyles"
import Footer from "../components/organisms/Footer/Footer"
import Hero from "../components/organisms/Hero/Hero"
import Menu from "../components/organisms/Menu/Menu"
import Minik from "../components/organisms/Minik/Minik"
import Section from "../components/organisms/Section/Section"
import GlobalProvider from "../context/GlobalContext"
import img1 from '../images/minik1.jpg'
import img2 from '../images/silka.png'
import of2 from '../images/of2.jpg'
import of3 from '../images/of3.jpg'
import of4 from '../images/of4.jpg'
import { Helmet } from "react-helmet"
import logo from '../images/logo.png'


const IndexPage = ({data}) => {
  return (
    <GlobalProvider>
      <Main >
        <GlobalStyle/>
        <Helmet>
          <meta charSet="utf-8" />
          <title>System Gaz</title>
          <meta name="description" content="System Gaz Radom. Projektowanie i wykonawstwo przyłączy: gazowych, wodnych, kanalizacyjnych, instalacji wewnętrznych." />
          <meta name="keywords" content="system, gaz, systemgaz, przylacza, gazowe, wodne, kanalizacyjne, instalacje wewnetrzne, instalacji wewnetrznych, przylacza gazowe, przylacza wodne, przylacza kanalizacyjne" />
          <meta name="image" content={logo} />
        </Helmet>
        <Menu />
        <Hero data={data} />
        <Section img={img2} smallTitle="Nasza oferta" title="Instalacje i zbiorniki gazowe na propan" id="zbiorniki" >
            <ul>
              <li>domu, firmy, przemysłu, rolnictwa</li>
              <li>podziemne i nadziemne</li>
              <li>fachowe doradztwo</li>
              <li>projekt instalacji gazowej</li>
              <li>montaż i instalacja</li>
              <li>serwis gwarancyjny</li>
            </ul>
        </Section>
        <Minik />
        <Section img={img1} smallTitle="Nasza oferta" title="przyłącza i sieci" id="przylacza" >
            <ul>
              <li>wodne</li>
              <li>gazowe</li>
              <li>kanalizacyjne</li>
            </ul>

            <p>W zakresie budowy przyłączy sanitarnych do budynków mieszkalnych, biurowych i przemysłowych</p>
            <p>Kompleksowa realizacja zadań w zakresie opracowania dokumentacji projektowej i  budowy obiektów liniowych - sicei gazowych, wodociągów i kanalizacyjnych.</p>
            
        </Section>
        <Section img={of2} smallTitle="Nasza oferta" title="PRZECISKI PODJEZDNIOWE" leftImg={true} id="przeciski" >
            <ul>
              <li>Wykonujemy usługi w zakresie przeprowadzania mediów bezwykopową metodą zapobiegającą naruszenia drogi bez konieczności jej niszczenia i odtwarzania oraz innych przeszkód występujących w terenie.</li>
              <li>Praktykujemy wykonanie przecisków do maksymalnej długości 20m w zakresie średnicy od 32 mm do 300 mm. Oferujemy w ramach przecisku wbijanie rur osłonowych (stalowe / PE),</li>
            </ul>
        </Section>
        <Section img={of3} smallTitle="Nasza oferta" title="wewnętrzne instalacje sanitarne" id="instalacje">
            <ul>
              <li>Świadczymy szeroki wachlarz usług instalacyjnych od projektowania i doboru urządzeń wysokiej jakości do kompleksowego wykonania instalacji grzewczych, wodnych i kanalizacyjnych.</li>
              <li>Nasze systemy dzięki niezawodności charakteryzują się ekonomiczną pracą i maksymalną wydajnością. Posiadamy bogate doświadczenie dzięki wykonanym instalacjom centralnego ogrzewania w domach jednorodzinnych, blokach i biurach. </li>
            </ul>
        </Section>
        <Section img={of4} smallTitle="Nasza oferta" title="PROJEKTOWANIE, WYKONAWSTWO" leftImg={true} id="projekt">
            <ul>
              <li>Sporządzimy projekt instalacji sieci sanitarnych, tj. gazowych, wodociągowych i kanalizacyjnych. Najlepsze rozwiązania technieczne. Wykonujemy naszą pracę dobierając najwyższej jakości materiały odpowiedzialne za długoletnią niezawodność.</li>
              <li>Oszacujemy i przeprowadzimy optymalny kosztorys względem wymagań klienta.</li>
              <li>w skład i cenę projektu wchodzą: <br/>
              <ul>
                <li>projekt instalacji/sieci</li>
                <li>mapa do celów projektowych</li>
              </ul>
                
                 </li>

            </ul>
        </Section>
        <Footer  />
      </Main>
    </GlobalProvider>
  )
}

const Main = styled.main`

  overflow: hidden;
`

export default IndexPage
