import styled from 'styled-components'
import React from 'react'


const Content = ({children}) => <Wrapper>{children}</Wrapper>

const Wrapper = styled.div`

    line-height: 1.4em;
    font-size: 1em;

    ul {
        list-style: none;
        margin: 10px 0 20px;
        padding: 0;

        &:last-child {
            margin-bottom: 0;
        }

        li {

            padding-left: 15px;
            position: relative;
            
             ul {
                 padding-left: 15px;
                 margin: 0;
             }
            
            &:before {
                content: '•';
                position: absolute;
                top: 0;
                left: 0;
                font-weight: 900;

            }
        }
    }

`

export default Content;